import { red } from '@material-ui/core/colors';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import hexbg2 from './hexbg2.svg'
// A custom theme for this app
const theme = responsiveFontSizes(createMuiTheme({
  transitions: {
    // So we have `transition: none;` everywhere
    create: () => 'none',
  },
  typography: {
    fontFamily: 'Rajdhani, sans-serif',
  },
  palette: {  
    primary: {
      main: '#ff5722',
      contrastText: '#fafafa',
    },
    secondary: {
      light: '#E1F5FE',
      main: '#00B8D4',
      contrastText: '#fafafa',
    },
    info: {
      main: '#00E5FF',
      light: '#fafaff',
      contrastText: '#fafafa',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    highlight: {
      main: '#ffcc33'
    },
    // dicePattern: {
    //   main: 
    //   backgroundColor: '#ffffff',
    //   backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'126\' height=\'84\' viewBox=\'0 0 126 84\'%3E%3Cg fill-rule=\'evenodd\'%3E%3Cg fill=\'%23ff6300\' fill-opacity=\'0.1\'%3E%3Cpath d=\'M126 83v1H0v-2h40V42H0v-2h40V0h2v40h40V0h2v40h40V0h2v83zm-2-1V42H84v40h40zM82 42H42v40h40V42zm-50-6a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM8 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm96 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm-42 0a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm30-12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM20 54a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm12 24a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM8 54a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm24 0a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM8 78a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm12 0a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm54 0a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM50 54a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm24 0a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM50 78a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm54-12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm12 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM92 54a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm24 0a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM92 78a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm24-42a4 4 0 1 1 0-8 4 4 0 0 1 0 8z\'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")',
    // }
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundImage: `url("${hexbg2}")`
        }
      }
    }
  }
}));

export default theme;
