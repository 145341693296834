/* eslint-disable import/prefer-default-export, react/prop-types */

import React from 'react';
import TopLayout from './TopLayout';

// export const wrapRootElement = ({ element, props }) => {
//   console.log('wrapRootElement', props)

//   return <TopLayout {...props}>{element}</TopLayout>;
// };

export const wrapPageElement = ({ element, props }) => {
  if (props.path === '/tools/gameGrid/') {
    return <React.Fragment>{element}</React.Fragment>
  }

  return <TopLayout {...props}>{element}</TopLayout>;
};
