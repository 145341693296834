import React from 'react-dom'
// import Firebase from './src/components/Firebase'
import "./src/styles/global.css"
import {
  createHistory,
  LocationProvider
} from "@reach/router"

if (process.env.NODE_ENV !== 'production') {
  console.log('debug', true)
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

export const onServiceWorkerUpdateFound = () => {
  window.location.reload();
};

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `La página ha sido actualizada. ` +
      `Actualizas a la última versión?`
  )
  if (answer === true) {
    window.location.reload()
  }
}

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
}

export const onRouteUpdate = () => {
  // Don't track while developing.
  if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
    // eslint-disable-next-line no-undef
    fbq("track", "ViewContent");
  }
};

// export function wrapPageElement({ element, props }) {
//   let history = createHistory(window)
//   return (
//     <LocationProvider history={history} {...props}>
//       {element}
//     </LocationProvider>
//   )
// }

